import { media, styled, Typography } from '@creditas-ui/react'
import { LazyImage } from 'components/LazyImage'
import { Colors } from 'utils/colors';

export const Wrapper = styled.section`
  width: 100%;
  background-color: #edf0ef;
`
export const Container = styled.div`
  max-width: 1366px;
  margin: 0 auto;
  display: grid;
  grid-row-gap: 48px;
  grid-template-areas:
    'gridlabel'
    'gridcards'
    'gridimage';

  ${media.up('5xl')} {
    grid-template-columns: 60% 40%;
    grid-template-areas:
      'gridlabel gridimage'
      'gridcards gridimage';
  }
`
export const TitleLabel = styled(Typography)`
  grid-area: gridlabel;
  padding: 96px 20px 0;

  ${media.up('5xl')} {
    padding: 0;
    margin-top: 160px;
    max-width: 600px;
  }
  ${media.between('5xl', '8xl')} {
    padding-left: 71px;
  }
`
export const CardContainer = styled.div`
  grid-area: gridcards;
  padding: 0 20px;
  display: flex;
  align-items: center;
  flex-direction: column;
  z-index: 2;

  ${media.up('5xl')} {
    width: 112%;
    padding: 0;
    margin-bottom: 160px;
    flex-direction: row;
  }
  ${media.between('5xl', '8xl')} {
    padding-left: 71px;
  }
`
export const StepBox = styled.div`
  display: flex;

  &:first-of-type {
    .first-dash {
      background-color: transparent;
    }
  }

  &:last-of-type {
    .last-dash {
      background-color: transparent;
    }
  }

  ${media.up('5xl')} {
    margin-right: 24px;

    :last-of-type {
      margin-right: 0;
    }
  }
`
export const Step = styled.div`
  display: flex;
  align-items: center;
  flex-direction: column;
  margin-right: 44px;

  .dot {
    border-radius: 50%;
    background-color: ${Colors.Green};
    min-width: 40px;
    min-height: 40px;
    max-width: 40px;
    max-height: 40px;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .first-dash {
    width: 2px;
    height: 60%;
    background-color: ${Colors.Green};
  }

  .last-dash {
    width: 2px;
    height: 100%;
    background-color: ${Colors.Green};
  }

  ${media.up('5xl')} {
    display: none;
  }
`
export const Card = styled.div`
  min-width: 236px;
  max-width: 288px;
  padding: 24px;
  box-sizing: border-box;
  box-shadow: 0 28px 64px rgba(31, 45, 39, 0.16);
  border-radius: 24px;
  background-color: #fff;
  margin-bottom: 56px;

  > h3 {
    margin: 38px 0 12px;
  }

  ${media.down('4xl')} {
    > h3 > span {
      display: none;
    }
  }

  ${media.up('5xl')} {
    margin-bottom: 0;
  }
`
export const ImageContainer = styled(LazyImage)`
  grid-area: gridimage;

  ${media.down('4xl')} {
    min-height: 284px;
    max-height: 500px;
  }
`

export const IconContainer = styled.div`
  ${media.between('3xl', '5xl')} {
    svg {
      width: 40px;
      height: 40px;
    }
  }
`
